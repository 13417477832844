footer {
  &.white {
    background: white;
  }
  ul {
    list-style-type: none;
    padding-left: 0;
    li {
      display: inline-block;
      position: relative;

      &:after {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: black;
        display: inline-block;
        margin: 0 10px 2px 10px;
      }

      &:last-of-type {
        margin-right: 0;
        &:after {
          display: none;
        }
      }
      @include media-breakpoint-down(sm) {
        &:nth-of-type(2) {
          margin-right: 0;
          &:after {
            display: none;
          }
        }
      }
    }
  }
  img {
    height: 51px;
    object-fit: scale-down;
  }
  .desktop-only {
    @include media-breakpoint-down(lg) {
      display: none !important;
    }
  }
  .mobile-only {
    @include media-breakpoint-down(sm) {
      display: block !important;
    }
    @include media-breakpoint-up(md) {
      display: none !important;
    }
  }

  &.footer-unauth {
    position: absolute;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    bottom: 0px;

    @include media-breakpoint-down(sm) {
      bottom: 0px;
      padding-right: 20px;
      padding-left: 20px;
      margin-bottom: 0px;
    }

    .terms-copy {
      color: #fff;
      text-align: left;
      padding-top: 10px;
      @include media-breakpoint-down(md) {
        font-size: 9px;
      }
    }

    .rgc-logo {
      text-align: right;
      @include media-breakpoint-down(md) {
        img {
          width: 137px;
        }
      }
    }
  }

  .footer-content {
    max-width: 1095px;
    width: 70%;
  }

  .column-footer-right {
    display: inline-flex;
    justify-content: center;
    @include media-breakpoint-up(xl) {
      justify-content: right;
    }
  }
  .column-footer-center {
    display: inline-flex;
    justify-content: center;
  }
}

.unauth-footer {
  a,
  a:focus,
  a:active {
    color: white;
  }

  .unauth-footer-separator {
    content: "";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: white;
    display: inline-block;
    margin: 0 10px 2px 10px;
    // padding: 0 $spacer * 0.5;
    @include media-breakpoint-down(sm) {
      margin: 0 5px 2px 5px;
      width: 3px;
      height: 3px;
    }
  }
}

.web-page-footer {
  background: #ebf0fa;
  position: relative;

  ul {
    list-style-type: none;
    padding-left: 0;
    li {
      display: inline-block;
      position: relative;

      &::after {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: black;
        display: inline-block;
        margin: 0 10px 2px 10px;
      }

      &:last-of-type {
        margin-right: 0;
        &::after {
          display: none;
        }
      }

      @include media-breakpoint-down(sm) {
        &:nth-of-type() {
          margin-right: 0;
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.web-page-footer::before {
  content: "";
  position: absolute;
  background-color: #ebf0fa;
  width: 100%;
  height: 1rem;
  border-radius: 60%;
  top: 0;
  transform-origin: top;
  transform: translate(0, -50%);
}

.referral-b-footer {
  position: relative;

  ul {
    list-style-type: none;
    padding-left: 0;
    li {
      display: inline-block;
      position: relative;

      &::after {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: black;
        display: inline-block;
        margin: 0 10px 2px 10px;
      }

      &:last-of-type {
        margin-right: 0;
        &::after {
          display: none;
        }
      }

      @include media-breakpoint-down(sm) {
        &:nth-of-type() {
          margin-right: 0;
          &::after {
            display: none;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    background-color: white;
  }
}
